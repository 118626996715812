import { formatDate, formatTime } from "@clipboard-health/date-time";
import { Text } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { CardActions, CardContent, Chip, Skeleton } from "@mui/material";
import { getShiftTimeProposalChatButtonLabel } from "@src/appV2/Chat/utils";
import { type Shift } from "@src/appV2/Shifts/Shift/types";
import { type GetShiftTimeProposalResponse } from "@src/appV2/Shifts/ShiftTimeProposals/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { parseISO } from "date-fns";

import { CbhCustomChatMessage } from "../CbhCustomChatMessage";
import { ShiftTimeProposalCardActions } from "./ShiftTimeProposalCardActions";

function formatShiftTime(startTime: string, endTime: string, label: string, timeZone: string) {
  return `${label} shift time: ${formatTime(startTime, { timeZone })} - ${formatTime(endTime, {
    timeZone,
  })}`;
}

interface ShiftTimeProposalMessageCardProps {
  originalStartTime?: string;
  originalEndTime?: string;
  shiftTimeProposal?: GetShiftTimeProposalResponse;
  shift?: Shift;
  isUpdateShiftTimeProposalLoading?: boolean;
  updatedStatus?: string;
  onAccept?: () => Promise<void>;
  onDecline?: () => Promise<void>;
}

export function ShiftTimeProposalMessageCard({
  originalStartTime,
  originalEndTime,
  shiftTimeProposal,
  shift,
  isUpdateShiftTimeProposalLoading = false,
  updatedStatus,
  onAccept,
  onDecline,
}: ShiftTimeProposalMessageCardProps) {
  const worker = useDefinedWorker();

  const proposedStartTime = shiftTimeProposal?.data?.attributes?.proposedStartTime;
  const proposedEndTime = shiftTimeProposal?.data?.attributes?.proposedEndTime;
  const status = shiftTimeProposal?.data?.attributes?.status;

  const hasShiftAndProposedStartTime = isDefined(shift) && isDefined(proposedStartTime);

  const isProposalExpired = hasShiftAndProposedStartTime
    ? new Date() > parseISO(proposedStartTime) ||
      isDefined(shift.clockInOut?.start) ||
      shift.agentId !== worker.userId ||
      shift.deleted === true
    : false;

  const isProposalActionable = isDefined(status) && status === "PROPOSED" && !isProposalExpired;

  const proposalMessage = isDefined(shift) ? (
    `${
      shift.facility.name
    } proposed a new time for your ${shift.name.toUpperCase()} shift on ${formatDate(
      shift.start,
      "MMM dd, yyyy"
    )}.`
  ) : (
    <>
      <Skeleton variant="text" width="100%" />
      <Skeleton variant="text" width="25%" />
    </>
  );

  const originalStartTimeMessage =
    isDefined(originalStartTime) && isDefined(originalEndTime) ? (
      formatShiftTime(originalStartTime, originalEndTime, "Original", worker.tmz)
    ) : (
      <Skeleton variant="text" width="70%" />
    );

  const newStartTimeMessage =
    isDefined(proposedStartTime) && isDefined(proposedEndTime) && isDefined(status) ? (
      formatShiftTime(
        proposedStartTime,
        proposedEndTime,
        status === "APPLIED" ? "New" : "Proposed",
        worker.tmz
      )
    ) : (
      <Skeleton variant="text" width="80%" />
    );

  const nonActionableChip = isDefined(status) ? (
    <Chip
      label={
        <Text variant="body1" color="primary.dark" sx={{ fontWeight: 600 }}>
          {getShiftTimeProposalChatButtonLabel({ status, isProposalExpired })}
        </Text>
      }
      sx={{ width: "100%", height: 42 }}
      variant="outlined"
    />
  ) : (
    <Skeleton variant="rectangular" height={42} width="100%" sx={{ borderRadius: 4 }} />
  );

  return (
    <CbhCustomChatMessage message={proposalMessage}>
      <CardContent>
        <Text variant="body2" color="text.primary">
          {originalStartTimeMessage}
        </Text>

        <Text variant="body2" color="primary.dark" sx={{ fontWeight: 600 }}>
          {newStartTimeMessage}
        </Text>
      </CardContent>

      <CardActions>
        {isProposalActionable ? (
          <ShiftTimeProposalCardActions
            isUpdateShiftTimeProposalLoading={isUpdateShiftTimeProposalLoading}
            updatedStatus={updatedStatus}
            onAccept={onAccept}
            onDecline={onDecline}
          />
        ) : (
          nonActionableChip
        )}
      </CardActions>
    </CbhCustomChatMessage>
  );
}
