import { Browser } from "@capacitor/browser";
import { Image, Text } from "@clipboard-health/ui-react";

import { DeprecatedGlobalAppV1Paths } from "../App/paths";
import { APP_V2_USER_EVENTS, logEvent } from "../lib/analytics";
import { LinkCard } from "../lib/Links/LinkCard";
import { useDefinedWorker } from "../Worker/useDefinedWorker";
import mobilePhoneImage from "./assets/images/mobilePhone.png";
import { useCareerAgentConfig } from "./useCareerAgentConfig";

export function CareerAgentBanner() {
  const { userId: workerId } = useDefinedWorker();
  const { title, description, url, method } = useCareerAgentConfig();

  async function handleClick(): Promise<void> {
    logEvent(APP_V2_USER_EVENTS.CAREER_AGENT_PAGE_VIEWED, { workerId });
    await Browser.open({
      url,
      toolbarColor: "#001529",
      presentationStyle: "fullscreen",
    });
  }

  return (
    <LinkCard
      to={{
        pathname:
          method === "iframe"
            ? DeprecatedGlobalAppV1Paths.CAREER_AGENT
            : DeprecatedGlobalAppV1Paths.ACCOUNT,
      }}
      title={
        <Text bold variant="body2">
          {title}
        </Text>
      }
      subtitle={<Text variant="caption">{description}</Text>}
      variant="info"
      leadingIcon={<Image src={mobilePhoneImage} alt="Phone" width={42} height={42} />}
      onClick={async () => {
        await handleClick();
      }}
    />
  );
}
