import { type EveryMessage } from "sendbird-uikit";

export enum CustomMessageTypes {
  SHIFT_REVIEW = "SHIFT_REVIEW",
  FCM_REQUEST = "FCM_REQUEST",
  FCM_APPROVAL = "FCM_APPROVAL",
  FCM_REJECT = "FCM_REJECT",
  INPUT_ETA = "INPUT_ETA",
  INPUT_ETA_SUBMITTED = "INPUT_ETA_SUBMITTED",
  SHIFT_INVITE_REQUEST = "SHIFT_INVITE_REQUEST",
  SHIFT_INVITE_RESPONSE = "SHIFT_INVITE_RESPONSE",
  PLACEMENT_APPLICATION = "PLACEMENT_APPLICATION",
  PLACEMENT_APPLICATION_AUTO_INTERVIEW_INVITE = "PLACEMENT_APPLICATION_AUTO_INTERVIEW_INVITE",
  SHIFT_TIME_PROPOSAL_CREATED = "SHIFT_TIME_PROPOSAL_CREATED",
  SHIFT_TIME_PROPOSAL_UPDATED = "SHIFT_TIME_PROPOSAL_UPDATED",
  SHIFT_TIME_PROPOSAL_REPLIED = "SHIFT_TIME_PROPOSAL_REPLIED",
}

export interface ShiftInviteMessageProps {
  message: EveryMessage;
}

export interface ShiftTimeProposalMessageProps {
  message: EveryMessage;
}
