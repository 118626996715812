import { isDefined, toError } from "@clipboard-health/util-ts";
import { type ShiftTimeProposalMessageProps } from "@src/appV2/Chat/type";
import { APP_V2_APP_EVENTS, useToast } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";
import { useGetShift } from "@src/appV2/Shifts/Shift/api/useGetShift";
import {
  useGetShiftTimeProposal,
  useUpdateShiftTimeProposal,
} from "@src/appV2/Shifts/ShiftTimeProposals/api/useShiftTimeProposals";
import { isObject, isString } from "lodash";

import { ShiftTimeProposalMessageCard } from "./ShiftTimeProposalMessageCard";

interface OriginalShiftTimeMessageData {
  originalStartTime: string;
  originalEndTime: string;
}

function hasOriginalShiftTime(data: unknown): data is OriginalShiftTimeMessageData {
  return (
    isObject(data) &&
    "originalStartTime" in data &&
    "originalEndTime" in data &&
    isString(data.originalStartTime) &&
    isString(data.originalEndTime)
  );
}

export function ShiftTimeProposalMessage(props: ShiftTimeProposalMessageProps) {
  const { message } = props;
  const { data } = message;
  // data contains single quotes, remove them before parsing
  const messageData: unknown = isDefined(data) ? JSON.parse(data.replace(/'/g, '"')) : undefined;
  const shiftTimeProposalId: string | undefined =
    typeof messageData === "object" &&
    isDefined(messageData) &&
    "shiftTimeProposalId" in messageData &&
    typeof messageData.shiftTimeProposalId === "string"
      ? messageData.shiftTimeProposalId
      : undefined;

  const { data: shiftTimeProposal, refetch: refetchShiftTimeProposal } = useGetShiftTimeProposal(
    { shiftTimeProposalId: shiftTimeProposalId ?? "" },
    { enabled: isDefined(shiftTimeProposalId) }
  );

  const {
    mutateAsync: updateShiftTimeProposalApi,
    isLoading: isUpdateShiftTimeProposalLoading,
    variables: updateShiftTimeProposalRequest,
  } = useUpdateShiftTimeProposal({ shiftTimeProposalId: shiftTimeProposalId ?? "" });
  const updatedStatus = updateShiftTimeProposalRequest?.data.attributes.status;

  const shiftId = shiftTimeProposal?.data.relationships.shift.data.id;

  const { data: shiftResponse } = useGetShift(shiftId ?? "", {
    enabled: isDefined(shiftId),
  });
  const { showSuccessToast, showErrorToast } = useToast();

  if (!isDefined(shiftTimeProposal) || !isDefined(shiftResponse) || !shiftResponse.success) {
    return <ShiftTimeProposalMessageCard />;
  }

  const shift = shiftResponse.response;
  const { start: shiftStartTime, end: shiftEndTime } = shift;

  // Determine original start and end times based on message data or shift data
  const originalStartTime = hasOriginalShiftTime(messageData)
    ? messageData.originalStartTime
    : shiftStartTime;

  const originalEndTime = hasOriginalShiftTime(messageData)
    ? messageData.originalEndTime
    : shiftEndTime;

  async function respondToShiftTimeProposal(status: "ACCEPTED" | "DECLINED") {
    const statusToConstants = {
      ACCEPTED: {
        actionEvent: APP_V2_APP_EVENTS.SHIFT_TIME_PROPOSAL_ACCEPTED,
        errorEvent: APP_V2_APP_EVENTS.SHIFT_TIME_PROPOSAL_ACCEPTED_ERROR,
        action: "accept",
      },
      DECLINED: {
        actionEvent: APP_V2_APP_EVENTS.SHIFT_TIME_PROPOSAL_DECLINED,
        errorEvent: APP_V2_APP_EVENTS.SHIFT_TIME_PROPOSAL_DECLINED_ERROR,
        action: "decline",
      },
    };
    try {
      await updateShiftTimeProposalApi({
        data: {
          type: "shift-time-proposal",
          id: shiftTimeProposalId ?? "",
          attributes: {
            status,
          },
        },
      });
      logEvent(statusToConstants[status].actionEvent, {
        shiftTimeProposalId,
        shiftId,
      });
      showSuccessToast(`New shift time ${status.toLowerCase()}`);
    } catch (maybeError) {
      const error = toError(maybeError);
      logEvent(statusToConstants[status].errorEvent, { error });

      showErrorToast(
        `Failed to ${statusToConstants[status].action} new shift time: ${error.message}`
      );
    }

    await refetchShiftTimeProposal();
  }

  return (
    <ShiftTimeProposalMessageCard
      originalStartTime={originalStartTime}
      originalEndTime={originalEndTime}
      shiftTimeProposal={shiftTimeProposal}
      shift={shift}
      isUpdateShiftTimeProposalLoading={isUpdateShiftTimeProposalLoading}
      updatedStatus={updatedStatus}
      onAccept={async () => {
        await respondToShiftTimeProposal("ACCEPTED");
      }}
      onDecline={async () => {
        await respondToShiftTimeProposal("DECLINED");
      }}
    />
  );
}
