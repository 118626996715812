import { Image, Text } from "@clipboard-health/ui-react";
import { Card, CardContent, Stack } from "@mui/material";
import { type ReactNode } from "react";

interface CbhCustomChatMessageProps {
  message: string | ReactNode;
  children?: ReactNode;
}

export function CbhCustomChatMessage(props: CbhCustomChatMessageProps) {
  const { message, children } = props;

  return (
    <Stack direction="row" spacing={1} marginBottom={1}>
      <Image
        width="48px"
        height="48px"
        src="assets/logo/bunny.png"
        alt="Clipboard logo"
        sx={{ marginTop: "auto" }}
      />
      <Stack flex={1}>
        <Text>Clipboard</Text>
        <Card elevation={2}>
          <CardContent sx={{ paddingBottom: "0px" }}>
            <Text variant="body2" color="text.primary">
              {message}
            </Text>
          </CardContent>
          {children}
        </Card>
      </Stack>
    </Stack>
  );
}
