import { isDefined } from "@clipboard-health/util-ts";
import { isEmpty } from "lodash";

import { logError } from "../lib/analytics";
import { type ShiftTimeProposalStatus } from "../Shifts/ShiftTimeProposals/types";
import { CustomMessageTypes, type ShiftInviteMessageProps } from "./type";

export function parseShiftInviteChatMessage(props: ShiftInviteMessageProps) {
  const { message } = props;
  const defaultData = { shiftInviteId: undefined, status: undefined };
  try {
    let agentMessage;
    if ("message" in message && typeof message.message === "string") {
      ({ agent: agentMessage } = JSON.parse(message.message) as { agent: string });
    }

    const { shiftInviteId, status } =
      isDefined(message.data) && !isEmpty(message.data)
        ? (JSON.parse(message.data) as {
            shiftInviteId: string;
            status: string;
          })
        : defaultData;
    return { agentMessage, shiftInviteId, status };
  } catch (error) {
    logError("Shift Invite Chat Message Parsing Error", { error, metadata: { message } });
    return { agentMessage: undefined, ...defaultData };
  }
}

export function shiftTimeProposalDisplayMessage(chatType: CustomMessageTypes) {
  if (
    [
      CustomMessageTypes.SHIFT_TIME_PROPOSAL_CREATED,
      CustomMessageTypes.SHIFT_TIME_PROPOSAL_UPDATED,
    ].includes(chatType)
  ) {
    return "Proposed a new time for your shift";
  }

  if (chatType === CustomMessageTypes.SHIFT_TIME_PROPOSAL_REPLIED) {
    return "You replied to the shift time proposal";
  }

  return undefined;
}

interface GetShiftTimeProposalChatButtonLabelProps {
  status: ShiftTimeProposalStatus;
  isProposalExpired: boolean;
}

export function getShiftTimeProposalChatButtonLabel({
  status,
  isProposalExpired,
}: GetShiftTimeProposalChatButtonLabelProps) {
  if (status === "PROPOSED" && isProposalExpired) {
    return "Expired";
  }

  switch (status) {
    case "ACCEPTED":
    case "APPLIED": {
      return "Accepted!";
    }

    case "DECLINED": {
      return "Declined";
    }

    default: {
      return "Unavailable";
    }
  }
}
