import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { Button, CircularProgress, Stack } from "@mui/material";

interface ShiftTimeProposalCardActionsProps {
  isUpdateShiftTimeProposalLoading: boolean;
  updatedStatus?: string;
  onAccept?: () => Promise<void>;
  onDecline?: () => Promise<void>;
}

export function ShiftTimeProposalCardActions({
  isUpdateShiftTimeProposalLoading,
  updatedStatus,
  onAccept,
  onDecline,
}: ShiftTimeProposalCardActionsProps) {
  return (
    <Stack direction="row" sx={{ width: "100%" }} spacing={1}>
      <Button
        fullWidth
        sx={{ backgroundColor: "primary.dark", borderRadius: 4, fontWeight: 600 }}
        variant="contained"
        size="small"
        startIcon={
          isUpdateShiftTimeProposalLoading && updatedStatus === "ACCEPTED" ? (
            <CircularProgress size={18} sx={{ color: "inherit" }} />
          ) : (
            <CheckIcon />
          )
        }
        disabled={isUpdateShiftTimeProposalLoading}
        onClick={onAccept}
      >
        Accept
      </Button>
      <Button
        fullWidth
        variant="outlined"
        sx={{ color: "primary.dark", borderColor: "grey.400", borderRadius: 4, fontWeight: 600 }}
        size="small"
        startIcon={
          isUpdateShiftTimeProposalLoading && updatedStatus === "DECLINED" ? (
            <CircularProgress size={18} sx={{ color: "inherit" }} />
          ) : (
            <CloseIcon />
          )
        }
        disabled={isUpdateShiftTimeProposalLoading}
        onClick={onDecline}
      >
        Decline
      </Button>
    </Stack>
  );
}
